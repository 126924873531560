import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-454d9e2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "access-code" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "containter-header" }
const _hoisted_4 = { class: "tagline" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "access-message" }
const _hoisted_7 = { class: "message" }
const _hoisted_8 = { class: "message" }
const _hoisted_9 = { class: "access-input" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_validation_text_field = _resolveComponent("validation-text-field")!
  const _component_FlatButton = _resolveComponent("FlatButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("sign.accessTaglineText")), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("sign.accessTitleText")), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("sign.accessFirstInfoLine")), 1),
        _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t("sign.accessSecondInfoLine")), 1)
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_validation_text_field, {
          value: _ctx.accessCode,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.accessCode) = $event)),
          type: "text",
          placeholder: _ctx.$t('sign.accessPlaceholder'),
          name: "accessCode"
        }, null, 8, ["value", "placeholder"])
      ]),
      _createVNode(_component_FlatButton, {
        onOnClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.userType === 'user' ? _ctx.submitAccessCode() : _ctx.submitLogin())),
        text: _ctx.$t('sign.verifyButton'),
        class: "verify-button"
      }, null, 8, ["text"])
    ])
  ]))
}