
// @ is an alias to /src
import FlatButton from "@/components/atomics/FlatButton.vue";
import { defineComponent, reactive, toRefs } from "vue";
import { storage, key } from "@/lib/storage";
import { useRouter } from "vue-router";
import {
  AuthRepository,
  ContractRepository,
  RepositoryFactory,
} from "@/lib/https";
import { useForm } from "vee-validate";
import useFormHandler from "@/lib/compositional-logic/useFormHandler";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import useSignFunctions from "@/lib/compositional-logic/useSignFunctions";

export default defineComponent({
  name: "AccessCode",
  components: {
    FlatButton,
    ValidationTextField,
  },
  setup() {
    const state = reactive({
      accessCode: "",
    });
    const localStorage = storage.getLocalStorage();
    const guestCode = localStorage.get("guestCode");
    const decode = Buffer.from(guestCode, "base64").toString();
    const details = JSON.parse(decode);
    const userType = localStorage.get("userType");
    const router = useRouter();

    const { saveAccessCode } = useSignFunctions();

    const { loginGuest } =
      RepositoryFactory.getRepository<AuthRepository>(AuthRepository);
    const { getContractFullInformation } =
      RepositoryFactory.getRepository<ContractRepository>(ContractRepository);

    const form = useForm({
      initialValues: {
        guestCode: guestCode ?? "",
        accessCode: state.accessCode ?? "",
      },
    });
    const { formHandle } = useFormHandler(form, loginGuest);
    const submitLogin = async () => {
      const response = await formHandle().catch((e) => {
        form.setErrors({
          accessCode: e.data.message,
        });
      });
      if (response) {
        localStorage.add(key.sessionId, response.sessionId);
        localStorage.add("userType", response.type);
        saveAccessCode(details.contractId, state.accessCode);
        router.push(`/guest/contract-guest?contractId=${response.contractId}`);
      }
    };

    const submitAccessCode = async () => {
      try {
        await getContractFullInformation(details.contractId, state.accessCode);
        saveAccessCode(details.contractId, state.accessCode);
        router.push({
          name: "SignDetails",
          query: {
            contractId: details.contractId,
          },
        });
      } catch (e: any) {
        form.setFieldError("accessCode", e.data.message);
      }
    };

    return { ...toRefs(state), submitLogin, userType, submitAccessCode };
  },
});
